import classNames from 'classnames';

import style from './style.module.scss';

type Props = {
  toggleVisible: () => void;
  isVisible: boolean;
};

export const DrawerHandle = ({ toggleVisible, isVisible }: Props) => {
  return (
    <div className={style.handler} onClick={toggleVisible}>
      <i
        className={classNames(style.drawerHandleIcon, {
          [style.drawerHandleIcon__open]: isVisible,
        })}
      />
    </div>
  );
};
