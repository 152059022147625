import classNames from 'classnames';
import { ReactNode } from 'react';

import style from './style.module.scss';

type Props = {
  isVisible: boolean;
  children: ReactNode;
};

export const MenuContainer = ({ isVisible, children }: Props) => {
  return (
    <div
      className={classNames(style.menuContainer, {
        [style.menuContainer__visible]: isVisible,
      })}
    >
      {children}
    </div>
  );
};
