import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  BillEntry,
  GetBillsListFilter,
  GetBillsListParams,
  WalletType,
  getBillsListRequest,
} from 'modules/api-requests/bills';
import { showNetworkErrorNotification } from 'modules/api-requests/utils';
import { RootState } from 'modules/store';

export type GetBillsListActionParams = Omit<GetBillsListParams, 'wallet'>;
export type GetBillsListActionFilter = Omit<GetBillsListFilter, 'wallet'>;

const billsAdapter = createEntityAdapter<BillEntry>();

export const billsInitState = billsAdapter.getInitialState({
  listLoading: true,
  totalRows: 0,
});

export const getEndpointBillsListAction = createAsyncThunk<{ bills: BillEntry[] }, GetBillsListActionParams>(
  'bills/getEndpointList',
  async (options) => {
    try {
      const response = await getBillsListRequest({ ...options, wallet: WalletType.dsp });
      const {
        data: { data: bills },
      } = response;

      return {
        bills,
      };
    } catch (err) {
      showNetworkErrorNotification();
      throw err;
    }
  }
);

export const getFeedBillsListAction = createAsyncThunk<{ bills: BillEntry[] }, GetBillsListActionParams>(
  'bills/getFeedList',
  async (options) => {
    try {
      const response = await getBillsListRequest({ ...options, wallet: WalletType.ssp });
      const {
        data: { data: bills },
      } = response;

      return {
        bills,
      };
    } catch (err) {
      showNetworkErrorNotification();
      throw err;
    }
  }
);

export const slice = createSlice({
  name: 'bills',
  initialState: billsInitState,
  reducers: {
    clearBillsList: (state) => {
      billsAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEndpointBillsListAction.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getEndpointBillsListAction.fulfilled, (state, { payload: { bills } }) => {
        state.listLoading = false;
        billsAdapter.removeAll(state);
        billsAdapter.addMany(state, bills);
      })
      .addCase(getEndpointBillsListAction.rejected, (state) => {
        state.listLoading = false;
      });

    builder
      .addCase(getFeedBillsListAction.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getFeedBillsListAction.fulfilled, (state, { payload: { bills } }) => {
        state.listLoading = false;
        billsAdapter.removeAll(state);
        billsAdapter.addMany(state, bills);
      })
      .addCase(getFeedBillsListAction.rejected, (state) => {
        state.listLoading = false;
      });
  },
});

export const bills = slice.reducer;

export type BillsState = typeof billsInitState;

const selectors = billsAdapter.getSelectors<RootState>((state) => state.bills);

export const getBillsMap = selectors.selectEntities;
export const getBillById = selectors.selectById;
export const isBillsListLoading = (state: RootState) => state.bills.listLoading;
export const getBillsTotalRows = (state: RootState) => state.bills.totalRows;
export const getBillsList = selectors.selectAll;
export const { clearBillsList } = slice.actions;
