import { useCallback, useEffect } from 'react';

import { pureLogoutAction } from 'models/authorization';
import { useAppDispatch } from 'modules/store';

export const LogoutManager = () => {
  const dispatch = useAppDispatch();
  const onLogout = useCallback(
    (event: StorageEvent) => {
      if (event.key === 'logout') {
        sessionStorage.setItem('lastPath', window.location.pathname);
        dispatch(pureLogoutAction());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('storage', onLogout);
    return () => {
      window.removeEventListener('storage', onLogout);
    };
  }, [onLogout]);

  return null;
};
