import { httpClient } from 'httpClient';

import { apiHost, apiVersion } from '../const';
import { ResponseAPI } from '../entities';
import { CurrentUser, UpdateUserParams } from './entities';

export const getCurrentUserRequest = (): Promise<ResponseAPI<CurrentUser>> =>
  httpClient.get(`${apiHost}/api/${apiVersion}/account/get`);

export const updateCurrentUserRequest = (data: UpdateUserParams): Promise<ResponseAPI<CurrentUser>> =>
  httpClient.post(`${apiHost}/api/${apiVersion}/account/update`, data);

export const regenerateApiKeyRequest = (): Promise<ResponseAPI<string>> =>
  httpClient.put(`${apiHost}/api/${apiVersion}/account/api-token/regenerate`);

export * from './entities';
