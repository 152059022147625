import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AnyAction, combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { emptyStore } from './emptyStore';
import * as reducers from './reducers';

const persistConfig = {
  key: 'app',
  version: 7,
  whitelist: ['authorization'],
  storage,
};

const reducer = combineReducers({
  ...reducers,
});

const rootReducer = (state: any, action: AnyAction) => {
  switch (action.type) {
    case PURGE: {
      return emptyStore;
    }
    default: {
      return reducer(state, action);
    }
  }
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof reducer>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
