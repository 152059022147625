import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { renderFixedMoney } from 'components/common/tables/columns';
import { PageRoute } from 'components/routing/utils';

import style from './style.module.scss';

type Props = {
  balance?: number;
  type: 'feed' | 'endpoint';
  testId: string;
};

export const Balance = ({ balance, type, testId }: Props) => {
  const tooltipText = type === 'feed' ? 'SSP Balance' : 'DSP Balance';
  const to = type === 'feed' ? PageRoute.sspBills : PageRoute.dspBills;

  return (
    <Tooltip title={tooltipText} placement="bottom">
      <Link to={to} data-testid={testId}>
        <div className={style.balanceBlock}>
          <i className={`fas fa-wallet ${style.balanceIcon}`} />
          {renderFixedMoney(2, true)(balance)}
        </div>
      </Link>
    </Tooltip>
  );
};
