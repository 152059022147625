import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { ReactNode } from 'react';

import { Currency } from 'modules/api-requests/entities';

import { formatNumber } from 'utils/formatNumber';

import { Flag } from '../Flag';
import { Money } from '../Money';
import { DateFormat, DateTimeFormat } from '../entities';

import style from './style.module.scss';

export type TableColumn<T> = ColumnType<T> & {
  index: string;
};

export type keyToColumnMapType<T, K extends string = string> = Record<K, TableColumn<T>>;

export const renderDummy = () => '–';

export const renderBold = (value: ReactNode) => <strong>{value}</strong>;

export const renderText = (value?: string | number) => value || renderDummy();

export const renderYesNo = (yes?: boolean) => (yes ? 'Yes' : 'No');

export const renderDate = (date: string | null) => {
  return date ? <span>{moment.utc(date).format(DateFormat)}</span> : renderDummy();
};

export const renderDateTime = (date: string | null) =>
  date ? <span>{moment.utc(date).format(DateTimeFormat)}</span> : renderDummy();

export const renderNumber =
  ({ fixed, split }: { fixed?: number; split?: boolean } = { split: true }) =>
  (value?: number) =>
    typeof value === 'number' ? formatNumber(value, { fixed, split }) : renderDummy();

export const renderPercent = (value: number) => `${Number(value).toFixed(2)} %`;

export type RenderCountryData = { code?: string; name?: string };

export const renderCountry = ({ code, name }: RenderCountryData) => {
  if (!code && !name) {
    return renderDummy();
  }
  return (
    <>
      {code && <Flag code={code} className={style.flag} />}
      {name}
    </>
  );
};

export const renderFixedMoney =
  (fixed: number, split = true, currency = Currency.USD) =>
  (value = 0) => <Money key={value} value={Number(value)} fixed={fixed} split={split} currency={currency} />;

export const getTestIdForColumn =
  (testIdNamespace: string) =>
  <T,>(column: ColumnType<T>) => ({
    'data-testid': `${testIdNamespace}:${column.dataIndex}`,
  });
