import { useSelector } from 'react-redux';

import { getStubMaintenanceShow } from 'models/stubMaintenance';

import { FrameMaintenance } from './FrameMaintenance';

export const StubMaintenance = () => {
  const isShow = useSelector(getStubMaintenanceShow);

  return isShow ? <FrameMaintenance /> : null;
};
