import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PURGE } from 'redux-persist';
import { createSelector } from 'reselect';

import { AuthToken, LoginRequestData, loginRequest } from 'modules/api-requests/authorization';
import { IError } from 'modules/api-requests/entities';
import { RootState } from 'modules/store';

import { getRandom } from 'utils/math';

export const loginAction = createAsyncThunk<AuthToken, LoginRequestData, { rejectValue: IError }>(
  'authorization/login',
  async (loginRequestData, { rejectWithValue, dispatch }) => {
    try {
      const response = await loginRequest(loginRequestData);
      const tokenData = response.data.data;
      dispatch(updateTokenAction(tokenData));

      return tokenData;
    } catch (err) {
      const error = err as AxiosError<IError>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const pureLogoutAction = createAsyncThunk('authorization/pureLogout', async (_, { dispatch }) => {
  dispatch(resetAuthStore());
  dispatch({ type: PURGE, key: 'persist:app', result: () => null });
});

export const logoutAction = createAsyncThunk('authorization/pureLogout', async (_, { dispatch }) => {
  localStorage.setItem('logout', getRandom());
  dispatch(pureLogoutAction());
});

export interface IAuthorizationState {
  isLoading: boolean;
  data?: AuthToken;
}

export const authorizationInitState: IAuthorizationState = {
  isLoading: false,
  data: undefined,
};

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState: authorizationInitState,
  reducers: {
    resetAuthStore: () => authorizationInitState,
    updateTokenAction: (state, action: PayloadAction<AuthToken>) => {
      const { refreshToken, token } = action.payload;
      state.data = {
        refreshToken,
        token,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(loginAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { resetAuthStore, updateTokenAction } = authorizationSlice.actions;

export const authorization = authorizationSlice.reducer;

export const getAuthorizationState = (state: RootState): IAuthorizationState => state.authorization;

export const getToken = (state: RootState) => state.authorization?.data?.token;

export const getRefreshToken = (state: RootState) => state.authorization.data?.refreshToken;

export const getIsAuthorized = createSelector([getToken], (token?: string): boolean => Boolean(token));

export const isLoading = (state: RootState) => state.authorization.isLoading;
