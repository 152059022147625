import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';

import { LazyLoad } from 'components/common/LazyLoad';
import { getIsAuthorized } from 'models/authorization';

import { RootBoundary } from './RootBoundary';
import { ProtectedRoutes } from './routes/protectedRoutes';
import { PageRoute } from './utils';

const LoginPage = React.lazy(() => import('components/loginPage'));
const LoginAsPage = React.lazy(() => import('components/LoginAsPage'));
const ForgotPasswordPage = React.lazy(() => import('components/ForgotPasswordPage'));
const NewPasswordPage = React.lazy(() => import('components/NewPasswordPage'));

const Root = () => {
  const isAuthorized = useSelector(getIsAuthorized);
  return (
    <Suspense fallback={<LazyLoad />}>
      <Routes>
        <Route path={PageRoute.authorization} element={<LoginPage />} />
        <Route path={PageRoute.loginAs} element={<LoginAsPage />} />
        <Route path={PageRoute.forgotPassword} element={<ForgotPasswordPage />} />
        <Route path={PageRoute.newPassword} element={<NewPasswordPage />} />
        {isAuthorized ? (
          <Route path="*" element={<ProtectedRoutes />} />
        ) : (
          <Route path="*" element={<Navigate to={PageRoute.authorization} />} />
        )}
      </Routes>
    </Suspense>
  );
};

const router = createBrowserRouter([{ path: '*', Component: Root, errorElement: <RootBoundary /> }]);

export const Routing = () => {
  return <RouterProvider router={router} />;
};
