import { httpClient } from 'httpClient';

import { apiHost, apiVersion } from '../const';
import { ListResponseAPIWithTotals, TableSettings } from '../entities';

export enum EndpointReportsBreakdown {
  DAY = 'DAY',
  COUNTRY = 'COUNTRY',
  ENDPOINTID = 'ENDPOINTID',
  SUBID = 'SUBID',
}

export type EndpointReportsFiltersType = {
  endpointId?: number;
  breakdown: EndpointReportsBreakdown;
  startDate: string;
  endDate: string;
};

export type EndpointReportsRequestProps = TableSettings & EndpointReportsFiltersType;

type EndpointReportsBreakdownFields = {
  date?: string;
  country?: string;
  countryAlpha2Code?: string;
  endpointId?: number;
  endpointName?: string;
  subid?: string;
};

export type EndpointReportsEntry = {
  avgCpc: number;
  clicks: number;
  ctr: number;
  ecpm: number;
  icons: number;
  srev: number;
} & EndpointReportsBreakdownFields;

export const getEndpointReportsRequest = (
  data: EndpointReportsRequestProps
): Promise<ListResponseAPIWithTotals<EndpointReportsEntry[], EndpointReportsEntry>> => {
  return httpClient.post(`${apiHost}/api/${apiVersion}/aggregates/endpoint`, data);
};
