import { MenuProps } from 'antd';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { PageRoute } from 'components/routing/utils';

import style from './style.module.scss';

type Props = {
  url: string;
  title: string;
  icon: ReactNode;
  extras?: ReactNode;
};

const MenuItemLabel = ({ url, title, icon, extras }: Props) => {
  return (
    <Link to={url}>
      <span className={style.title}>{title}</span>
      {extras}
      {icon}
    </Link>
  );
};

type ExtrasProps = {
  sspEnabled?: boolean;
  dspEnabled?: boolean;
};

type GetMenuItems = (props: ExtrasProps) => MenuProps['items'];

const sspGroupKey = 'sspGroup';
const dspGroupKey = 'dspGroup';

export const getUserMenuItems: GetMenuItems = ({ sspEnabled, dspEnabled }) => {
  const menuItems = [];
  if (sspEnabled) {
    menuItems.push({
      key: sspGroupKey,
      label: 'SSP',
      children: [
        {
          key: PageRoute.feeds,
          label: (
            <MenuItemLabel icon={<i className={`${style.icon} fas fa-star`} />} title="Feeds" url={PageRoute.feeds} />
          ),
        },
        {
          key: PageRoute.sspReport,
          label: (
            <MenuItemLabel
              icon={<i className={`${style.icon} fas fa-file-lines`} />}
              title="Reports"
              url={PageRoute.sspReport}
            />
          ),
        },
        {
          key: PageRoute.sspBills,
          label: (
            <MenuItemLabel
              icon={<i className={`${style.icon} fas fa-money-bill`} />}
              title="Billing"
              url={PageRoute.sspBills}
            />
          ),
        },
        {
          key: PageRoute.sspApi,
          label: (
            <MenuItemLabel icon={<i className={`${style.icon} fas fa-cloud`} />} title="API" url={PageRoute.sspApi} />
          ),
        },
      ],
    });
  }
  if (dspEnabled) {
    menuItems.push({
      key: dspGroupKey,
      label: 'DSP',
      children: [
        {
          key: PageRoute.endpoints,
          label: (
            <MenuItemLabel
              icon={<i className={`${style.icon} fas fa-globe`} />}
              title="Endpoints"
              url={PageRoute.endpoints}
            />
          ),
        },
        {
          key: PageRoute.dspReport,
          label: (
            <MenuItemLabel
              icon={<i className={`${style.icon} fas fa-file-lines`} />}
              title="Reports"
              url={PageRoute.dspReport}
            />
          ),
        },
        {
          key: PageRoute.dspBills,
          label: (
            <MenuItemLabel
              icon={<i className={`${style.icon} fas fa-money-bill`} />}
              title="Billing"
              url={PageRoute.dspBills}
            />
          ),
        },
        {
          key: PageRoute.dspApi,
          label: (
            <MenuItemLabel icon={<i className={`${style.icon} fas fa-cloud`} />} title="API" url={PageRoute.dspApi} />
          ),
        },
      ],
    });
  }
  return [
    ...menuItems,
    {
      key: PageRoute.profile,
      label: (
        <MenuItemLabel
          icon={<i className={`${style.icon} fas fa-cogs`} />}
          title="Account Settings"
          url={PageRoute.profile}
        />
      ),
    },
  ];
};

type GetDefaultOpenKeysProps = ExtrasProps & {
  isCollapsed: boolean;
};

export const getDefaultOpenKeys = ({ sspEnabled, dspEnabled, isCollapsed }: GetDefaultOpenKeysProps): string[] => {
  if (isCollapsed) return [];
  if (sspEnabled) return [sspGroupKey];
  if (dspEnabled) return [dspGroupKey];
  return [];
};
