import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { LazyLoad } from 'components/common/LazyLoad';
import App from 'components/common/app';
import { getUserDefaultRoute } from 'components/loginPage/utils';
import { PageRoute } from 'components/routing/utils';
import { getCurrentUser, getCurrentUserAction } from 'models/currentUser';
import { useAppDispatch } from 'modules/store';

const FeedListPage = React.lazy(() => import('components/FeedListPage'));
const FeedReportsPage = React.lazy(() => import('components/FeedReportsPage'));
const FeedBillingsPage = React.lazy(() => import('components/bills/FeedBillsPage'));
const FeedApiPage = React.lazy(() => import('components/api/FeedApiPage'));
const EndpointListPage = React.lazy(() => import('components/EndpointListPage'));
const EndpointReportsPage = React.lazy(() => import('components/EndpointReportsPage'));
const EndpointBillingsPage = React.lazy(() => import('components/bills/EndpointBillsPage'));
const AccountSettingsPage = React.lazy(() => import('components/AccountSettingsPage'));
const EndpointApiPage = React.lazy(() => import('components/api/EndpointApiPage'));

export const ProtectedRoutes = () => {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);
  const defaultRoute = getUserDefaultRoute(currentUser);

  useEffect(() => {
    if (!currentUser) {
      dispatch(getCurrentUserAction());
    }
  }, [currentUser, dispatch]);

  if (!currentUser) return;

  return (
    <App>
      <Suspense fallback={<LazyLoad />}>
        <Routes>
          {currentUser.sspEnabled && (
            <>
              <Route path={PageRoute.feeds} element={<FeedListPage />} />
              <Route path={PageRoute.sspReport} element={<FeedReportsPage />} />
              <Route path={PageRoute.sspBills} element={<FeedBillingsPage />} />
              <Route path={PageRoute.sspApi} element={<FeedApiPage />} />
            </>
          )}
          {currentUser.dspEnabled && (
            <>
              <Route path={PageRoute.endpoints} element={<EndpointListPage />} />
              <Route path={PageRoute.dspReport} element={<EndpointReportsPage />} />
              <Route path={PageRoute.dspBills} element={<EndpointBillingsPage />} />
              <Route path={PageRoute.dspApi} element={<EndpointApiPage />} />
            </>
          )}
          <Route path={PageRoute.profile} element={<AccountSettingsPage />} />
          <Route path="*" element={<Navigate to={defaultRoute} />} />
        </Routes>
      </Suspense>
    </App>
  );
};
