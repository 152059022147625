import { authorizationInitState } from 'models/authorization';
import { billsInitState } from 'models/bills';
import { currentUserInitState } from 'models/currentUser';
import { dictionaryInitState } from 'models/dictionary';
import { endpointReportsInitState } from 'models/endpointReports';
import { endpointsInitState } from 'models/endpoints';
import { feedReportsInitState } from 'models/feedReports';
import { feedsInitState } from 'models/feeds';
import { stubMaintenanceInitState } from 'models/stubMaintenance';
import { techDataInitState } from 'models/techData';

import { RootState } from '.';

export const emptyStore: RootState = {
  authorization: authorizationInitState,
  currentUser: currentUserInitState,
  dictionary: dictionaryInitState,
  endpoints: endpointsInitState,
  endpointReports: endpointReportsInitState,
  bills: billsInitState,
  feeds: feedsInitState,
  feedReports: feedReportsInitState,
  techData: techDataInitState,
  stubMaintenance: stubMaintenanceInitState,
};
