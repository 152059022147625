import { PageRoute, isRoute, publicRoutes } from 'components/routing/utils';
import { CurrentUser } from 'modules/api-requests/currentUser';

export const getUserDefaultRoute = (user?: CurrentUser): PageRoute => {
  if (user?.sspEnabled) return PageRoute.feeds;
  if (user?.dspEnabled) return PageRoute.endpoints;
  return PageRoute.profile;
};

export const getNextRoute = (user: CurrentUser): PageRoute => {
  const lastPath = sessionStorage.getItem('lastPath');
  if (isRoute(lastPath) && !publicRoutes.includes(lastPath)) return lastPath;
  return getUserDefaultRoute(user);
};

export const testIdNamespace = 'LoginPage';
