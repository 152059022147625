import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FeedReportsEntry, FeedReportsRequestProps, getFeedReportsRequest } from 'modules/api-requests/feedReports';
import { showNetworkErrorNotification } from 'modules/api-requests/utils';
import { RootState } from 'modules/store';

export type feedReportsState = {
  loading: boolean;
  items: FeedReportsEntry[];
  totals: FeedReportsEntry;
  totalRows: number;
};

export const getfeedReportsAction = createAsyncThunk<
  {
    items: FeedReportsEntry[];
    totals: FeedReportsEntry;
    totalRows: number;
  },
  FeedReportsRequestProps
>('feedReports/getList', async (options) => {
  try {
    const response = await getFeedReportsRequest(options);
    const { totalRows } = response.data.meta;
    const { data: items, totals } = response.data;

    return { items, totalRows, totals };
  } catch (err) {
    showNetworkErrorNotification();
    throw err;
  }
});

export const feedReportsInitState: feedReportsState = {
  loading: false,
  items: [],
  totalRows: 0,
  totals: {
    avgCpc: 0,
    clicks: 0,
    ctr: 0,
    ecpm: 0,
    icons: 0,
    spent: 0,
  },
};

export const slice = createSlice({
  name: 'feedReports',
  initialState: feedReportsInitState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getfeedReportsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getfeedReportsAction.fulfilled, (state, { payload: { items, totals, totalRows } }) => {
        state.loading = false;
        state.items = items;
        state.totals = totals;
        state.totalRows = totalRows;
      })
      .addCase(getfeedReportsAction.rejected, (state) => {
        state.items = feedReportsInitState.items;
        state.totals = feedReportsInitState.totals;
        state.loading = false;
      });
  },
});

export const feedReports = slice.reducer;

export const isfeedReportsLoading = (state: RootState) => state.feedReports.loading;
export const getfeedReportsList = (state: RootState) => state.feedReports.items;
export const getfeedReportsTotals = (state: RootState) => state.feedReports.totals;
export const getfeedReportsTotalRows = (state: RootState) => state.feedReports.totalRows;
