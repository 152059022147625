import { Layout, Menu } from 'antd';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Logo, logoHeight } from 'components/common/Logo';
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_MIN } from 'components/const';
import { getUserDefaultRoute } from 'components/loginPage/utils';
import { getCurrentUser } from 'models/currentUser';

import { getDefaultOpenKeys, getUserMenuItems } from './menuItems';

import style from './style.module.scss';

const siderTriggerHeight = 48;
const { Sider } = Layout;

type LeftMenuProps = {
  isTight: boolean;
  isMobile: boolean;
  isCollapsed: boolean;
  toggle: () => void;
};

export const LeftMenu = ({ isTight, isMobile, isCollapsed, toggle }: LeftMenuProps) => {
  const location = useLocation();
  const { pathname } = location;
  const user = useSelector(getCurrentUser);

  const userEnabledFlags = useMemo(
    () => ({ sspEnabled: user?.sspEnabled, dspEnabled: user?.dspEnabled }),
    [user?.dspEnabled, user?.sspEnabled]
  );
  const items = useMemo(() => {
    return getUserMenuItems(userEnabledFlags);
  }, [userEnabledFlags]);
  const defaultOpenKeys = getDefaultOpenKeys({ ...userEnabledFlags, isCollapsed });

  return (
    <Sider
      className={`${style.menu} ${style.white}`}
      collapsible
      collapsed={isCollapsed}
      onCollapse={toggle}
      width={isTight ? SIDEBAR_WIDTH_MIN : SIDEBAR_WIDTH}
    >
      <Link to={getUserDefaultRoute(user)}>
        <Logo hidden={isMobile}>
          {isCollapsed ? (
            <img src="/img/logo-dark-sm.svg" alt="M" style={{ width: '60%' }} />
          ) : (
            <img src="/img/logo-dark.svg" alt="mondiad.com" style={{ width: '70%' }} />
          )}
        </Logo>
      </Link>
      <Menu
        selectedKeys={[pathname]}
        items={items}
        className={style.navigation}
        mode="inline"
        inlineIndent={15}
        style={{
          height: `calc(100vh - ${logoHeight}px - ${siderTriggerHeight}px)`,
        }}
        defaultOpenKeys={defaultOpenKeys}
      />
    </Sider>
  );
};
