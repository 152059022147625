import { httpClient } from 'httpClient';

import { apiHost, apiVersion } from '../const';
import { ResponseAPI } from '../entities';
import { CountryDictionaryItem, DictionaryItem } from './entities';

export const dictionaryCountriesRequest = (): Promise<ResponseAPI<CountryDictionaryItem[]>> => {
  return httpClient.get(`${apiHost}/api/${apiVersion}/countries`);
};

export const dictionaryMessengersRequest = (): Promise<ResponseAPI<DictionaryItem[]>> => {
  return httpClient.get(`${apiHost}/api/${apiVersion}/messengers`);
};

export * from './entities';
