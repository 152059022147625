import NProgress from 'nprogress';
import { useEffect } from 'react';

export const LazyLoad = () => {
  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return null;
};
