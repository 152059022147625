import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { notification } from 'antd';
import axios from 'axios';

import { getToken, logoutAction, updateTokenAction } from 'models/authorization';
import { showStubMaintenance } from 'models/stubMaintenance';
import { refreshTokenRequest } from 'modules/api-requests/authorization';
import { isServerError } from 'modules/api-requests/utils';
import { AppDispatch } from 'modules/store';

// avoid circular dependencies
let store: ToolkitStore;
export const injectStore = (_store: ToolkitStore) => {
  store = _store;
};

export const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-US',
  },
});

httpClient.interceptors.request.use((request) => {
  const state = store.getState();

  const token = getToken(state);
  const isRefreshReq = request.url?.endsWith('auth/refreshToken');

  if (token && !isRefreshReq) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});

const logout = async (dispatch: AppDispatch) => {
  const currentPath = window.location.pathname;
  sessionStorage.setItem('lastPath', currentPath);
  await dispatch(logoutAction());
};

httpClient.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const dispatch: AppDispatch = store.dispatch;
    const status = error?.response?.status;

    if (error.response?.status === 502) {
      dispatch(showStubMaintenance());
    }

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const state = store.getState();
        const refreshToken = state.authorization.data?.refreshToken;
        const res = await refreshTokenRequest(refreshToken);
        const tokenData = res.data.data;
        dispatch(updateTokenAction(tokenData));
        return httpClient(originalRequest);
      } catch (err) {
        await logout(dispatch);

        notification.error({
          message: 'Your session is expired',
          description:
            isServerError(err) && err.fieldErrors?.token ? err.fieldErrors.token : 'Token is not valid any more',
        });

        return Promise.reject(err);
      }
    }

    if (status === 403) {
      await logout(dispatch);
    }

    return Promise.reject(error);
  }
);
