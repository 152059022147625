import { httpClient } from 'httpClient';

import { apiHost, apiVersion } from '../const';
import { ListResponseAPI, TableSettings } from '../entities';

export type GetEndpointsListFilters = {
  allFields?: string;
  id?: string;
  name?: string;
  token?: string;
  isActive?: boolean;
};

export type EndpointEntry = {
  id: number;
  name: string;
  token: string;
  active: boolean;
};

export type EndpointSearch = Pick<EndpointEntry, 'id' | 'name'>;

export type GetEndpointListParams = GetEndpointsListFilters & TableSettings;

export const getEndpointsListRequest = (
  data: GetEndpointListParams,
  signal?: AbortSignal
): Promise<ListResponseAPI<EndpointEntry[]>> => {
  return httpClient.post(`${apiHost}/api/${apiVersion}/endpoints/list`, data, { signal });
};
