import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MediaSizes, getWindowWidth, isTableViewport } from 'models/techData';

import { DrawerHandle } from './DrawerHandle';
import { MenuContainer } from './MenuContainer';
import { SidebarBox } from './SidebarBox';
import { LeftMenu } from './menu-left';
import { useIsCollapsed } from './useIsCollapsed';

export const LeftSidebar = () => {
  const isTablet = useSelector(isTableViewport);
  const windowWidth = useSelector(getWindowWidth);
  const isTight = windowWidth <= MediaSizes.LARGE_WIDTH;

  const { isCollapsed, toggleCollapse } = useIsCollapsed(isTablet);
  const [isVisible, setVisible] = useState(!isTablet);
  const toggleVisible = () => setVisible(!isVisible);

  useEffect(() => {
    if (!isVisible && !isTablet) {
      setVisible(true);
    }
  }, [isVisible, isTablet]);

  return (
    <SidebarBox isCollapsed={isCollapsed} isMobile={isTablet} isTight={isTight}>
      <MenuContainer isVisible={isVisible}>
        <LeftMenu isTight={isTight} isMobile={isTablet} isCollapsed={isCollapsed} toggle={toggleCollapse} />

        {isTablet && <DrawerHandle isVisible={isVisible} toggleVisible={toggleVisible} />}
      </MenuContainer>
    </SidebarBox>
  );
};
