import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  EndpointReportsEntry,
  EndpointReportsRequestProps,
  getEndpointReportsRequest,
} from 'modules/api-requests/endpointReports';
import { showNetworkErrorNotification } from 'modules/api-requests/utils';
import { RootState } from 'modules/store';

export type EndpointReportsState = {
  loading: boolean;
  items: EndpointReportsEntry[];
  totals: EndpointReportsEntry;
  totalRows: number;
};

export const getEndpointReportsAction = createAsyncThunk<
  {
    items: EndpointReportsEntry[];
    totals: EndpointReportsEntry;
    totalRows: number;
  },
  EndpointReportsRequestProps
>('endpointReports/getList', async (options) => {
  try {
    const response = await getEndpointReportsRequest(options);
    const { totalRows } = response.data.meta;
    const { data: items, totals } = response.data;

    return { items, totalRows, totals };
  } catch (err) {
    showNetworkErrorNotification();
    throw err;
  }
});

export const endpointReportsInitState: EndpointReportsState = {
  loading: false,
  items: [],
  totalRows: 0,
  totals: {
    avgCpc: 0,
    clicks: 0,
    ctr: 0,
    ecpm: 0,
    icons: 0,
    srev: 0,
  },
};

export const slice = createSlice({
  name: 'endpointReports',
  initialState: endpointReportsInitState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEndpointReportsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEndpointReportsAction.fulfilled, (state, { payload: { items, totals, totalRows } }) => {
        state.loading = false;
        state.items = items;
        state.totals = totals;
        state.totalRows = totalRows;
      })
      .addCase(getEndpointReportsAction.rejected, (state) => {
        state.items = endpointReportsInitState.items;
        state.totals = endpointReportsInitState.totals;
        state.loading = false;
      });
  },
});

export const endpointReports = slice.reducer;

export const isEndpointReportsLoading = (state: RootState) => state.endpointReports.loading;
export const getEndpointReportsList = (state: RootState) => state.endpointReports.items;
export const getEndpointReportsTotals = (state: RootState) => state.endpointReports.totals;
export const getEndpointReportsTotalRows = (state: RootState) => state.endpointReports.totalRows;
