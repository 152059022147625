import { httpClient } from 'httpClient';

import { apiHost, apiVersion } from '../const';
import { ListResponseAPI } from '../entities';
import { BillEntry, GetBillsListParams } from './entites';

export const getBillsListRequest = (data: GetBillsListParams): Promise<ListResponseAPI<BillEntry[]>> => {
  return httpClient.post(`${apiHost}/api/${apiVersion}/bills/list`, data);
};

export * from './entites';
