// 123456789 -> "123,456,789"
export const splitNumber = (value: number | string) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const separator = '.';

type Options = {
  fixed?: number;
  split?: boolean;
};

export const formatNumber = (value: number, { fixed, split }: Options = {}): string => {
  const strValue = fixed ? value.toFixed(fixed) : value.toString();

  if (!split) {
    return strValue;
  }

  if (!strValue.includes(separator)) {
    return splitNumber(strValue);
  }
  const values = strValue.split('.');

  return splitNumber(values[0]) + '.' + values[1];
};
