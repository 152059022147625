export enum PageRoute {
  authorization = '/login',
  loginAs = '/login-as',
  newPassword = '/password/recovery',
  feeds = '/ssp/feeds',
  sspReport = '/ssp/reports',
  sspBills = '/ssp/bills',
  sspApi = '/ssp/api',
  endpoints = '/dsp/endpoints',
  dspReport = '/dsp/reports',
  dspBills = '/dsp/bills',
  dspApi = '/dsp/api',
  contact = '/contact',
  profile = '/profile',
  forgotPassword = '/resetting/request',
}

export const publicRoutes = [PageRoute.authorization, PageRoute.newPassword, PageRoute.forgotPassword];

export const isRoute = (value: unknown): value is PageRoute => Object.values(PageRoute).includes(value as PageRoute);
