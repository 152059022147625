import { ConfigProvider, Layout, Spin } from 'antd';
import classnames from 'classnames';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { StubMaintenance } from 'components/StubMaintenance';
import { Header } from 'components/common/app/header';
import { LeftSidebar } from 'components/common/app/sidebar-left';
import { Footer } from 'components/layout/Footer';
import { isTableViewport } from 'models/techData';

import { useWindowWidth } from 'utils/hooks/useWindowWidth';

import style from './style.module.scss';

type Props = {
  children: ReactNode;
};

Spin.setDefaultIndicator(<i className="fas fa-spinner fa-pulse" style={{ fontSize: '1.5rem' }} />);

export const App = ({ children }: Props) => {
  const isTablet = useSelector(isTableViewport);
  useWindowWidth();

  return (
    <ConfigProvider virtual={false}>
      <StubMaintenance />
      <Layout className={style.appBox}>
        <LeftSidebar />
        <main
          className={classnames(style.pageBox, {
            [style.pageBox__tablet]: isTablet,
          })}
        >
          <Header isTablet={isTablet} />
          {children}
          <Footer />
        </main>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
