import { Result } from 'antd';
import { useEffect } from 'react';

import { getAllCheck } from 'modules/api-requests/allCheck';

import style from './style.module.scss';

const DELAY = 30000;

export const FrameMaintenance = () => {
  const checkServer = async () => {
    try {
      await getAllCheck();
      location.reload();
    } catch (e) {
      // do nothing
    }
  };

  useEffect(() => {
    const interval = setInterval(checkServer, DELAY);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={style.background}>
      <Result
        icon={<img src="/img/logo-dark.svg" alt="mondiad.com" className={style.img} />}
        className={style.frame}
        title="Mondiad is currently under maintenance"
        subTitle="We expect to be back in a couple of minutes. Thanks for your patience."
      />
    </div>
  );
};
