import { httpClient } from 'httpClient';

import { apiHost, apiVersion } from 'modules/api-requests/const';
import { ResponseAPI } from 'modules/api-requests/entities';

import { AuthToken, ChangePasswordData, LoginRequestData } from './entities';

export const loginRequest = (data: LoginRequestData): Promise<ResponseAPI<AuthToken>> =>
  httpClient.post(`${apiHost}/api/${apiVersion}/auth/login`, data);

export const refreshTokenRequest = (refreshToken?: string): Promise<ResponseAPI<AuthToken>> =>
  httpClient.post(`${apiHost}/api/${apiVersion}/auth/refreshToken`, {
    token: refreshToken,
  });

export const changePasswordRequest = (data: ChangePasswordData): Promise<ResponseAPI<AuthToken>> =>
  httpClient.post(`${apiHost}/api/${apiVersion}/account/change-password`, data);

export * from './entities';
