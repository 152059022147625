import { useMemo } from 'react';

import { Currency } from 'modules/api-requests/entities';

import { formatNumber } from 'utils/formatNumber';
import { noExponents } from 'utils/noExponents';

import styles from './style.module.scss';

const mapCurrencyIcon: Record<Currency, string> = {
  [Currency.USD]: 'fa-dollar-sign',
  [Currency.EUR]: 'fa-euro-sign',
  [Currency.GBP]: 'fa-pound-sign',
};

type Props = {
  value: number;
  fixed?: number;
  split?: boolean;
  currency?: Currency;
};

export const Money = ({ value, fixed, split, currency = Currency.USD }: Props) => {
  const title = useMemo(() => noExponents(formatNumber(value, { fixed, split })), [value, fixed, split]);
  const sign = mapCurrencyIcon[currency];

  return (
    <span className={styles.valueBox}>
      <i className={`fas ${sign} ${styles.valueSign}`} />
      <span title={title} className={styles.value}>
        {title}
      </span>
    </span>
  );
};
