import { httpClient } from 'httpClient';

import { apiHost, apiVersion } from '../const';
import { ListResponseAPI, TableSettings } from '../entities';

export type FeedEntry = {
  id: number;
  name: string;
  createdAt: string;
  minCpc?: number;
  enabled: boolean;
};

export type GetFeedsListParams = GetFeedsListFilters & TableSettings;
export type GetFeedsListFilters = {
  allFields?: string;
  id?: string;
  name?: string;
  enabled?: boolean;
};

export const getFeedsListRequest = (
  params: GetFeedsListParams,
  signal?: AbortSignal
): Promise<ListResponseAPI<FeedEntry[]>> => {
  return httpClient.post(`${apiHost}/api/${apiVersion}/ssp/feeds/list`, params, { signal });
};
