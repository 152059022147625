import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

export enum MediaSizes {
  MOBILE_WIDTH = 576,
  TABLET_WIDTH = 768,
  MEDIUM_WIDTH = 992,
  LARGE_WIDTH = 1200,
  EXTRA_LARGE_WIDTH = 9999,
}

export type TechDataState = {
  windowWidth: number;
};

export const techDataInitState: TechDataState = {
  windowWidth: window.innerWidth,
};

export const slice = createSlice({
  name: 'techData',
  initialState: techDataInitState,
  reducers: {
    setWindowWidth: (state, action: PayloadAction<number>) => {
      state.windowWidth = action.payload;
    },
  },
});

export const techData = slice.reducer;
export const { setWindowWidth } = slice.actions;

export const getWindowWidth = (state: RootState) => state.techData.windowWidth;
export const isMobileViewport = createSelector(
  [getWindowWidth],
  (windowWidth) => windowWidth <= MediaSizes.MOBILE_WIDTH
);
export const isTableViewport = createSelector(
  [getWindowWidth],
  (windowWidth) => windowWidth <= MediaSizes.MEDIUM_WIDTH
);
export const isMediumViewport = createSelector(
  [getWindowWidth],
  (windowWidth) => windowWidth >= MediaSizes.MEDIUM_WIDTH
);
export const isLargeViewport = createSelector([getWindowWidth], (windowWidth) => windowWidth >= MediaSizes.LARGE_WIDTH);
