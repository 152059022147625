import { Dropdown } from 'antd';

import { getUserName } from 'models/currentUser/utils';
import { CurrentUser } from 'modules/api-requests/currentUser';

import style from './style.module.scss';

type Props = {
  logout: () => void;
  user?: CurrentUser;
  isTablet?: boolean;
  testIdNamespace: string;
};

export const ProfileMenu = ({ user, logout, isTablet, testIdNamespace }: Props) => {
  const userName = user ? getUserName(user) : 'User Menu';
  const items = [
    {
      key: 'logout',
      onClick: logout,
      label: 'Logout',
      'data-testid': `${testIdNamespace}:ProfileMenu:logout`,
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <div className={style.dropdownContainer} data-testid={`${testIdNamespace}:ProfileMenu`}>
        {isTablet ? (
          <i className={`fas fa-cog ${style.profileIcon}`} />
        ) : (
          <div className={style.userName}>{userName}</div>
        )}
      </div>
    </Dropdown>
  );
};
