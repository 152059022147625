import classNames from 'classnames';
import { ReactNode } from 'react';

import style from './style.module.scss';

export const logoHeight = 64;

type Props = {
  children: ReactNode;
  hidden?: boolean;
  collapsed?: boolean;
};

export const Logo = ({ children, hidden, collapsed }: Props) => (
  <div
    className={classNames(style.logo, {
      [style.logo__hidden]: hidden,
      [style.logo__collapsed]: collapsed,
    })}
  >
    {children}
  </div>
);
