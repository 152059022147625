export enum GrantType {
  password = 'PASSWORD',
  authCode = 'AUTH_CODE',
}

type LoginByPasswordData = {
  grantType: GrantType.password;
  email: string;
  password: string;
  remember: boolean;
};
export type LoginByAuthCodeData = {
  grantType: GrantType.authCode;
  email: string;
  authCode: string;
};
export type LoginRequestData = LoginByPasswordData | LoginByAuthCodeData;

export type AuthToken = {
  refreshToken: string;
  token: string;
};

export type ChangePasswordData = {
  newPassword: string;
  oldPassword: string;
  refreshToken: string;
};
