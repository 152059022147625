import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import 'antd/lib/style/index.less';
import { injectStore } from 'httpClient';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { LogoutManager } from 'components/authorization/LogountManager';
import 'components/kit/vendors/antd/themes/dark.less';
import 'components/kit/vendors/antd/themes/default.less';
import { Routing } from 'components/routing';
import 'modules/global-styles/global.scss';
import { persistor, store } from 'modules/store';

injectStore(store);

const Main = () => {
  return (
    <PersistGate loading={<i />} persistor={persistor}>
      <Routing />
      <LogoutManager />
    </PersistGate>
  );
};

const Index = () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <Main />
      </Provider>
    </HelmetProvider>
  );
};

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);
root.render(<Index />);
