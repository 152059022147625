import React from 'react';

type Props = React.HTMLAttributes<HTMLImageElement> & {
  code: string;
};

export const Flag = ({ code, ...rest }: Props) => {
  // images from https://github.com/hjnilsson/country-flags
  const path = `/img/flags/${String(code).toLowerCase()}.svg`;
  if (code === '\u0000\u0000') return null;
  return (
    <img
      {...rest}
      src={path}
      alt={code}
      onError={(e) => {
        const img = e.target as HTMLImageElement;
        img.remove();
      }}
    />
  );
};
