import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

export type StubMaintenanceType = {
  isShow: boolean;
};

export const stubMaintenanceInitState: StubMaintenanceType = {
  isShow: false,
};

export const stubMaintenanceSlice = createSlice({
  name: 'stubMaintenance',
  initialState: stubMaintenanceInitState,
  reducers: {
    showStubMaintenance: (state) => {
      state.isShow = true;
    },
  },
});

export const stubMaintenance = stubMaintenanceSlice.reducer;
export const { showStubMaintenance } = stubMaintenanceSlice.actions;

export const getStubMaintenanceShow = (state: RootState): boolean => state.stubMaintenance.isShow;
