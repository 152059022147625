class AbortControllerServiceClass {
  private controllerMap: Record<string, AbortController> = {};

  triggerController(actionName: string) {
    const existedController = this.controllerMap[actionName];
    if (existedController) {
      existedController.abort();
    }

    const updatedController = new AbortController();
    this.controllerMap[actionName] = updatedController;
    return updatedController;
  }
}

export const abortControllerService = new AbortControllerServiceClass();
