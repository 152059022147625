import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';

import {
  CurrentUser,
  UpdateUserParams,
  getCurrentUserRequest,
  regenerateApiKeyRequest,
  updateCurrentUserRequest,
} from 'modules/api-requests/currentUser';
import { IError } from 'modules/api-requests/entities';
import { isAxiosServerError } from 'modules/api-requests/utils';
import { RootState } from 'modules/store';

export const getCurrentUserAction = createAsyncThunk<CurrentUser, void, { rejectValue: IError }>(
  'currentUser/get',
  async () => {
    const { data } = await getCurrentUserRequest();
    return data.data;
  }
);

export const regenerateApiKeyAction = createAsyncThunk('currentUser/regenerateApi', async () => {
  const { data } = await regenerateApiKeyRequest();
  return data.data;
});

export const updateCurrentUserAction = createAsyncThunk<CurrentUser, UpdateUserParams, { rejectValue?: IError }>(
  'currentUser/update',
  async (params, { rejectWithValue }) => {
    try {
      const { data: response } = await updateCurrentUserRequest(params);
      notification.success({
        message: 'Profile has been updated',
      });
      return response.data;
    } catch (err) {
      if (isAxiosServerError(err)) {
        return rejectWithValue(err.response?.data);
      }

      notification.error({
        message: 'Something went wrong',
      });
      throw err;
    }
  }
);

export type CurrentUserState = {
  isLoading: boolean;
  isUpdating: boolean;
  data?: CurrentUser;
};

export const currentUserInitState: CurrentUserState = {
  isLoading: false,
  isUpdating: false,
  data: undefined,
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: currentUserInitState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrentUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = {
          ...action.payload,
        };
      })
      .addCase(getCurrentUserAction.rejected, (state) => {
        state.isLoading = false;
        state.data = undefined;
      });

    builder
      .addCase(updateCurrentUserAction.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateCurrentUserAction.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(updateCurrentUserAction.rejected, (state) => {
        state.isUpdating = false;
      });

    builder
      .addCase(regenerateApiKeyAction.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(regenerateApiKeyAction.fulfilled, (state, action) => {
        state.isUpdating = false;

        if (state.data) state.data.apiKey = action.payload;
      })
      .addCase(regenerateApiKeyAction.rejected, (state) => {
        state.isUpdating = false;
      });
  },
});

export const currentUser = currentUserSlice.reducer;

export const getCurrentUser = (state: RootState) => state.currentUser.data;
export const getIsLoading = (state: RootState) => state.currentUser.isLoading;
export const getIsUpdating = (state: RootState) => state.currentUser.isUpdating;
export const getCurrentYear = (state: RootState) => state.currentUser.data?.currentYear;
