import { ReactNode } from 'react';

import { COLLAPSED_SIDEBAR_WIDTH, SIDEBAR_WIDTH, SIDEBAR_WIDTH_MIN } from 'components/const';

import style from './style.module.scss';

type Props = {
  isCollapsed: boolean;
  isMobile: boolean;
  isTight: boolean;
  children: ReactNode;
};

const getWidth = ({ isCollapsed, isTight, isMobile }: Omit<Props, 'children'>) => {
  if (isMobile) return 0;
  if (isCollapsed) return COLLAPSED_SIDEBAR_WIDTH;
  return isTight ? SIDEBAR_WIDTH_MIN : SIDEBAR_WIDTH;
};

export const SidebarBox = ({ isCollapsed, isTight, isMobile, children }: Props) => {
  const width = getWidth({ isCollapsed, isMobile, isTight });
  return (
    <div
      style={{
        flex: `0 0 ${width}px`,
        maxWidth: `${width}px`,
        minWidth: `${width}px`,
        width: `${width}px`,
      }}
      className={style.sidebarBox}
    >
      {children}
    </div>
  );
};
