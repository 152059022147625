import { TableSettings } from '../entities';

export enum BillStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  HELD = 'HELD',
  REJECTED = 'REJECTED',
  REFUNDED = 'REFUNDED',
  CHARGEBACK = 'CHARGEBACK',
  ERROR = 'ERROR',
  DELETED = 'DELETED',
  CANCELED = 'CANCELED',
}

export enum BillType {
  ADJUSTMENT = 'ADJUSTMENT',
  CASH_OUT = 'CASH_OUT',
  DEPOSIT = 'DEPOSIT',
}

export enum BillProcessor {
  ADJUSTMENT_BONUS = 'ADJUSTMENT_BONUS',
  PAYPAL_PROCESSOR = 'PAYPAL_PROCESSOR',
  PAYZA_PROCESSOR = 'PAYZA_PROCESSOR',
  PAXUM_PROCESSOR = 'PAXUM_PROCESSOR',
  SKRILL_PROCESSOR = 'SKRILL_PROCESSOR',
  WEBMONEY_PROCESSOR = 'WEBMONEY_PROCESSOR',
  WIRE = 'WIRE',
  PAYONEER_PROCESSOR = 'PAYONEER_PROCESSOR',
  PAYSERA_PROCESSOR = 'PAYSERA_PROCESSOR',
  PERFECT_MONEY_PROCESSOR = 'PERFECT_MONEY_PROCESSOR',
  EPAYMENTS_PROCESSOR = 'EPAYMENTS_PROCESSOR',
  CRYPTOPAY_BITCOIN_PROCESSOR = 'CRYPTOPAY_BITCOIN_PROCESSOR',
  CRYPTOPAY_ETHEREUM_PROCESSOR = 'CRYPTOPAY_ETHEREUM_PROCESSOR',
  CRYPTOPAY_RIPPLE_PROCESSOR = 'CRYPTOPAY_RIPPLE_PROCESSOR',
  CRYPTOPAY_LITECOIN_PROCESSOR = 'CRYPTOPAY_LITECOIN_PROCESSOR',
  CRYPTOPAY_BITCOIN_CASH_PROCESSOR = 'CRYPTOPAY_BITCOIN_CASH_PROCESSOR',
  CRYPTOPAY_STELLAR_PROCESSOR = 'CRYPTOPAY_STELLAR_PROCESSOR',
  CRYPTOPAY_TETHER_PROCESSOR = 'CRYPTOPAY_TETHER_PROCESSOR',
  CRYPTOPAY_USD_COIN_PROCESSOR = 'CRYPTOPAY_USD_COIN_PROCESSOR',
  CRYPTOPAY_DAI_PROCESSOR = 'CRYPTOPAY_DAI_PROCESSOR',
  CRYPTOPAY_CARDANO_PROCESSOR = 'CRYPTOPAY_CARDANO_PROCESSOR',
  CRYPTOPAY_TRON_PROCESSOR = 'CRYPTOPAY_TRON_PROCESSOR',
  CRYPTOPAY_DOGECOIN_PROCESSOR = 'CRYPTOPAY_DOGECOIN_PROCESSOR',
  CRYPTOPAY_BNB_PROCESSOR = 'CRYPTOPAY_BNB_PROCESSOR',
  CRYPTOPAY_SOLANA_PROCESSOR = 'CRYPTOPAY_SOLANA_PROCESSOR',
  CRYPTOPAY_SHIBA_INU_PROCESSOR = 'CRYPTOPAY_SHIBA_INU_PROCESSOR',
  ADJUSTMENT_PENALTY = 'ADJUSTMENT_PENALTY',
  ADJUSTMENT_IT_PLUS = 'ADJUSTMENT_IT_PLUS',
  ADJUSTMENT_IT_MINUS = 'ADJUSTMENT_IT_MINUS',
  FIRST_CHOICE_PAY_PROCESSOR = 'FIRST_CHOICE_PAY_PROCESSOR',
  CAPITALIST = 'CAPITALIST',
}

export enum WalletType {
  ssp = 'SSP',
  dsp = 'DSP',
}

export type GetBillsListFilter = {
  allFields?: string;
  id?: string;
  types?: BillType;
  processors?: BillProcessor;
  amount?: string;
  email?: string;
  startDate?: string;
  endDate?: string;
  status?: BillStatus;
  wallet: WalletType;
};

export type GetBillsListParams = TableSettings & GetBillsListFilter;

export type BillEntry = {
  id: number;
  timestamp: string;
  type: BillType;
  processor: BillProcessor;
  email: string;
  amount: number;
  status: BillStatus;
  fee?: number;
};
