import { Result } from 'antd';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { loggingRequest } from 'modules/api-requests/logging';

import style from './style.module.scss';

type AppError = Error & {
  code?: string;
};

const isChunkError = (err: AppError) => {
  return err.name === 'ChunkLoadError' || err.code === 'CSS_CHUNK_LOAD_FAILED';
};

export const RootBoundary = () => {
  const error = useRouteError();
  const err = error as AppError;

  useEffect(() => {
    if (isChunkError(err)) {
      window.location.reload();
      return;
    }
    loggingRequest({
      message: err.message,
      stackTrace: err.stack,
      source: 'Push members',
      url: window.location.href,
    });
  }, [err]);

  if (err.name === 'ChunkLoadError' || err.code === 'CSS_CHUNK_LOAD_FAILED') {
    window.location.reload();
    return (
      <div className={style.background}>
        <Result
          icon={<img src="/img/logo-dark.svg" alt="mondiad.com" className={style.img} />}
          className={style.result}
          title="The app has been updated"
        />
      </div>
    );
  }

  return (
    <div className={style.background}>
      <Result
        icon={<img src="/img/logo-dark.svg" alt="mondiad.com" className={style.img} />}
        className={style.result}
        title="Something went wrong"
        subTitle="Try to reload the app"
      />
    </div>
  );
};
