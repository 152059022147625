export const SIDEBAR_WIDTH = 256;
export const SIDEBAR_WIDTH_MIN = 216;
export const COLLAPSED_SIDEBAR_WIDTH = 80;

export const PASSWORD_VALIDATION_PATTERN = /((?=.*[@$!%*?&]))(?=(.*[A-z])+)(?=(.*\d)+)/;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 250;

export const RGBA_COLOR_PATTERN = /^rgba\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d*(?:\.\d+)?)\)$/;

export const HEX_COLOR_PATTERN = /^#([A-Fa-f\d]{6}|[A-Fa-f\d]{3})$/;
