import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { abortControllerService } from 'modules/abortController';
import { EndpointEntry, GetEndpointListParams, getEndpointsListRequest } from 'modules/api-requests/endpoints';
import { showNetworkErrorNotification } from 'modules/api-requests/utils';
import { RootState } from 'modules/store';

const endpointsAdapter = createEntityAdapter<EndpointEntry>();

export const endpointsInitState = endpointsAdapter.getInitialState<{
  loading: boolean;
  totalRows: number;
}>({
  loading: true,
  totalRows: 0,
});

export const fetchEndpointsAction = createAsyncThunk<
  { endpoints: EndpointEntry[]; totalRows: number },
  GetEndpointListParams
>('endpoints/fetchList', async (options) => {
  try {
    const { signal } = abortControllerService.triggerController('fetch-endpoints-list');
    const response = await getEndpointsListRequest(options, signal);
    const {
      data,
      meta: { totalRows },
    } = response.data;
    return { endpoints: data, totalRows };
  } catch (err) {
    if (!axios.isCancel(err)) {
      showNetworkErrorNotification();
    }
    throw err;
  }
});

export const slice = createSlice({
  name: 'endpoints',
  initialState: endpointsInitState,
  reducers: {
    clearEndpointsList: (state) => {
      endpointsAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEndpointsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEndpointsAction.fulfilled, (state, { payload: { endpoints, totalRows } }) => {
        state.loading = false;
        state.totalRows = totalRows;
        endpointsAdapter.removeAll(state);
        endpointsAdapter.addMany(state, endpoints);
      })
      .addCase(fetchEndpointsAction.rejected, (state, { error }) => {
        if (error?.message !== 'canceled') {
          state.loading = false;
        }
      });
  },
});

export const endpoints = slice.reducer;

export type EndpointsState = typeof endpointsInitState;

const selectors = endpointsAdapter.getSelectors<RootState>((state) => state.endpoints);

export const getEndpointsMap = selectors.selectEntities;
export const getEndpointById = selectors.selectById;
export const isEndpointsLoading = (state: RootState) => state.endpoints.loading;
export const getEndpointsTotalRows = (state: RootState) => state.endpoints.totalRows;
export const getEndpointsList = selectors.selectAll;
export const { clearEndpointsList } = slice.actions;
