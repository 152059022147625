import { AxiosResponse } from 'axios';

type Meta = {
  totalRows: number;
  totalPages: number;
  serverTime?: string;
};

export type ResponseAPI<T> = AxiosResponse<{ data: T }>;
export type ListResponseAPI<T> = AxiosResponse<{ data: T; meta: Meta }>;
export type ListResponseAPIWithTotals<T, K> = AxiosResponse<{ data: T; meta: Meta; totals: K }>;

export interface IFieldErrors {
  [key: string]: string;
}

export interface IError {
  message?: string;
  errors?: string[];
  error?: string;
  status: number;
  fieldErrors: IFieldErrors;
}

export enum SortDirections {
  DESC = 'DESC',
  ASC = 'ASC',
}

export type TableSettings = {
  size: number;
  page: number;
  sortBy?: string;
  sortDirection?: SortDirections;
};

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
}
