import { Layout } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Logo } from 'components/common/Logo';
import { getUserDefaultRoute } from 'components/loginPage/utils';
import { logoutAction } from 'models/authorization';
import { getCurrentUser } from 'models/currentUser';
import { useAppDispatch } from 'modules/store';

import { Balance } from './Balance';
import { ProfileMenu } from './profile-menu';
import { testIdNamespace } from './utils';

import style from './style.module.scss';

const { Header: AntdHeader } = Layout;

type Props = {
  isTablet?: boolean;
};

export const Header = ({ isTablet }: Props) => {
  const dispatch = useAppDispatch();
  const user = useSelector(getCurrentUser);
  const logout = () => {
    const currentPath = window.location.pathname;
    sessionStorage.setItem('lastPath', currentPath);
    dispatch(logoutAction());
  };

  return (
    <AntdHeader className={classNames(style.header, { [style.header__tablet]: isTablet })}>
      {isTablet && (
        <Link to={getUserDefaultRoute(user)}>
          <Logo collapsed>
            <img src="/img/logo-dark-sm.svg" alt="M" style={{ width: '60%' }} />
          </Logo>
        </Link>
      )}

      <div className={style.headerControls}>
        {user?.sspEnabled && (
          <Balance balance={user?.sspBalance} type="feed" testId={`${testIdNamespace}:sspBalance`} />
        )}
        {user?.dspEnabled && (
          <Balance balance={user?.dspBalance} type="endpoint" testId={`${testIdNamespace}:dspBalance`} />
        )}
        <ProfileMenu user={user} logout={logout} isTablet={isTablet} testIdNamespace={testIdNamespace} />
      </div>
    </AntdHeader>
  );
};
