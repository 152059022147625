import { useCallback, useState } from 'react';

const collapsedKey = 'LeftSidebarStorage:collapsed';

const storage = {
  getCollapsed(): boolean | null {
    const value = localStorage.getItem(collapsedKey);

    return value === null ? value : value === 'true';
  },
  setCollapsed(isCollapsed: boolean) {
    return localStorage.setItem(collapsedKey, String(isCollapsed));
  },
};

export function useIsCollapsed(isTablet: boolean) {
  const valueFromStorage = storage.getCollapsed();
  const initValue = valueFromStorage === null ? isTablet : valueFromStorage;

  const [isCollapsed, setCollapsed] = useState(initValue);

  const toggleCollapse = useCallback(() => {
    setCollapsed((isCollapsed) => {
      storage.setCollapsed(!isCollapsed);
      return !isCollapsed;
    });
  }, []);

  return { isCollapsed, toggleCollapse };
}
