import { useEffect } from 'react';

import { setWindowWidth } from 'models/techData';
import { useAppDispatch } from 'modules/store';

export const useWindowWidth = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    function handleResize() {
      dispatch(setWindowWidth(window.innerWidth));
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);
};
