import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  CountryDictionaryItem,
  DictionaryItem,
  dictionaryCountriesRequest,
  dictionaryMessengersRequest,
} from 'modules/api-requests/dictionary';
import { RootState } from 'modules/store';

export enum Dictionaries {
  countries = 'countries',
  messengers = 'messengers',
}

export type DictionaryState = {
  [Dictionaries.countries]: EntityState<CountryDictionaryItem> & { loading: boolean };
  [Dictionaries.messengers]: EntityState<DictionaryItem> & { loading: boolean };
};

const countriesAdapter = createEntityAdapter<CountryDictionaryItem>();
const messengersAdapter = createEntityAdapter<DictionaryItem>();

export const dictionaryInitState: DictionaryState = {
  [Dictionaries.countries]: countriesAdapter.getInitialState({
    loading: true,
  }),
  [Dictionaries.messengers]: messengersAdapter.getInitialState({
    loading: true,
  }),
};

const countriesSelectors = countriesAdapter.getSelectors(
  (state: RootState) => state.dictionary[Dictionaries.countries]
);
export const { selectAll: getCountriesList, selectById: getCountryById } = countriesSelectors;

export const isCountriesLoading = (state: RootState) => state.dictionary[Dictionaries.countries].loading;

export const getCountriesAction = createAsyncThunk<CountryDictionaryItem[]>(
  'dictionary/Countries',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const existsData = getCountriesList(state);
    if (!existsData || existsData.length === 0) {
      const { data } = await dictionaryCountriesRequest();
      // remove Satellite Provider
      return data.data.filter(({ code }) => code !== 'A2');
    }
    return existsData;
  }
);

const messengersSelectors = messengersAdapter.getSelectors(
  (state: RootState) => state.dictionary[Dictionaries.messengers]
);
export const {
  selectEntities: getMessengersMap,
  selectAll: getMessengersList,
  selectById: getMessengerById,
} = messengersSelectors;

export const isMessengersLoading = (state: RootState) => state.dictionary[Dictionaries.messengers].loading;

export const getMessengersAction = createAsyncThunk<DictionaryItem[]>(
  'dictionary/Messengers',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const existsData = getMessengersList(state);
    if (!existsData || existsData.length === 0) {
      const { data } = await dictionaryMessengersRequest();
      return data.data;
    }
    return existsData;
  }
);

const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState: dictionaryInitState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesAction.pending, (state) => {
        state[Dictionaries.countries].loading = true;
      })
      .addCase(getCountriesAction.fulfilled, (state, { payload }) => {
        countriesAdapter.setAll(state[Dictionaries.countries], payload);
        state[Dictionaries.countries].loading = false;
      });

    builder
      .addCase(getMessengersAction.pending, (state) => {
        state[Dictionaries.messengers].loading = true;
      })
      .addCase(getMessengersAction.fulfilled, (state, { payload }) => {
        messengersAdapter.setAll(state[Dictionaries.messengers], payload);
        state[Dictionaries.messengers].loading = false;
      });
  },
});

export const dictionary = dictionarySlice.reducer;
