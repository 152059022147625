import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { abortControllerService } from 'modules/abortController';
import { FeedEntry, GetFeedsListParams, getFeedsListRequest } from 'modules/api-requests/feeds';
import { showNetworkErrorNotification } from 'modules/api-requests/utils';
import { RootState } from 'modules/store';

const feedsAdapter = createEntityAdapter<FeedEntry>();

export const feedsInitState = feedsAdapter.getInitialState<{
  loading: boolean;
  totalRows: number;
}>({
  loading: true,
  totalRows: 0,
});

export const fetchFeedsAction = createAsyncThunk<{ items: FeedEntry[]; totalRows: number }, GetFeedsListParams>(
  'feeds/fetchList',
  async (options) => {
    try {
      const { signal } = abortControllerService.triggerController('feeds-fetch-list');
      const response = await getFeedsListRequest(options, signal);
      const {
        data: items,
        meta: { totalRows },
      } = response.data;

      return {
        items,
        totalRows,
      };
    } catch (err) {
      if (!axios.isCancel(err)) {
        showNetworkErrorNotification();
      }
      throw err;
    }
  }
);

export const slice = createSlice({
  name: 'feeds',
  initialState: feedsInitState,
  reducers: {
    clearFeedsList: (state) => {
      feedsAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFeedsAction.fulfilled, (state, { payload: { items, totalRows } }) => {
        state.loading = false;
        state.totalRows = totalRows;
        feedsAdapter.removeAll(state);
        feedsAdapter.addMany(state, items);
      })
      .addCase(fetchFeedsAction.rejected, (state, { error }) => {
        if (error?.message !== 'canceled') {
          state.loading = false;
        }
      });
  },
});

export const feeds = slice.reducer;

export type FeedsState = typeof feedsInitState;

const selectors = feedsAdapter.getSelectors<RootState>((state) => state.feeds);

export const getFeedsMap = selectors.selectEntities;
export const getFeedById = selectors.selectById;
export const isFeedsLoading = (state: RootState) => state.feeds.loading;
export const getFeedsTotalRows = (state: RootState) => state.feeds.totalRows;
export const getFeedsList = selectors.selectAll;
export const { clearFeedsList } = slice.actions;
