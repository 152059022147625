import { Layout } from 'antd';
import { useSelector } from 'react-redux';

import { getCurrentYear } from 'models/currentUser';

import style from './style.module.scss';

export const Footer = () => {
  const currentYear = useSelector(getCurrentYear);

  return (
    <Layout.Footer className={style.footer}>
      <div className={style.copyright}>Copyright © 2020-{currentYear} Mondiad</div>
    </Layout.Footer>
  );
};
