import { notification } from 'antd';
import { AxiosError, isAxiosError } from 'axios';

import { IError } from './entities';

const defaultMessage = 'Internet connection error. Please try again later';

export const showNetworkErrorNotification = (message = defaultMessage) => {
  notification.warn({
    message: 'Network error',
    description: message,
  });
};

export const isServerError = (error: unknown): error is IError => {
  const serverError = error as IError;
  return 'message' in serverError || 'errors' in serverError || 'fieldErrors' in serverError;
};

export const isAxiosServerError = (err: unknown): err is AxiosError<IError> => {
  return isAxiosError(err) && isServerError(err.response?.data);
};

export const getFieldErrors = (err: unknown) => {
  if (isAxiosServerError(err)) {
    return err?.response?.data?.fieldErrors;
  }
};

export const showServerError = (err: unknown) => {
  if (isAxiosServerError(err)) {
    notification.error({
      message: err.response?.data.message,
    });
  } else {
    showNetworkErrorNotification();
  }
};
