import { httpClient } from 'httpClient';

import { apiHost, apiVersion } from '../const';
import { ListResponseAPIWithTotals, TableSettings } from '../entities';

export enum FeedReportsBreakdown {
  DAY = 'DAY',
  COUNTRY = 'COUNTRY',
  FEEDID = 'FEEDID',
  SUBID = 'SUBID',
}

export type FeedReportsFiltersType = {
  feedId?: number;
  breakdown: FeedReportsBreakdown;
  startDate: string;
  endDate: string;
};

export type FeedReportsRequestProps = TableSettings & FeedReportsFiltersType;

type FeedReportsBreakdownFields = {
  date?: string;
  country?: string;
  countryAlpha2Code?: string;
  feedId?: number;
  feedName?: string;
  subid?: string;
};

export type FeedReportsEntry = {
  avgCpc: number;
  clicks: number;
  ctr: number;
  ecpm: number;
  icons: number;
  spent: number;
} & FeedReportsBreakdownFields;

export const getFeedReportsRequest = (
  data: FeedReportsRequestProps
): Promise<ListResponseAPIWithTotals<FeedReportsEntry[], FeedReportsEntry>> => {
  return httpClient.post(`${apiHost}/api/${apiVersion}/aggregates/feed`, data);
};
